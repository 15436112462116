import { createIcon } from "@chakra-ui/react";

const MobilePhoneIcon = createIcon({
  displayName: "MobilePhoneIcon",
  viewBox: "0 0 9 14",
  path: [
    <g clipPath="url(#clip0_44_533)" key="1">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56814 2.1875H1.34592V1.75C1.34592 1.267 1.74414 0.875 2.23481 0.875H6.67925C7.16992 0.875 7.56814 1.267 7.56814 1.75V2.1875ZM1.34592 3.0625H7.56814V8.75H1.34592V3.0625ZM7.56814 12.25C7.56814 12.733 7.16992 13.125 6.67925 13.125H2.23481C1.74414 13.125 1.34592 12.733 1.34592 12.25V9.625H7.56814V12.25ZM6.67925 0H2.23481C1.25303 0 0.457031 0.783562 0.457031 1.75V12.25C0.457031 13.2164 1.25303 14 2.23481 14H6.67925C7.66103 14 8.45703 13.2164 8.45703 12.25V1.75C8.45703 0.783562 7.66103 0 6.67925 0ZM4.45703 12.25C4.9477 12.25 5.34592 11.858 5.34592 11.375C5.34592 10.892 4.9477 10.5 4.45703 10.5C3.96636 10.5 3.56814 10.892 3.56814 11.375C3.56814 11.858 3.96636 12.25 4.45703 12.25Z"
      />
    </g>,
    <defs key="2">
      <clipPath id="clip0_44_533">
        <rect
          width="8"
          height="14"
          fill="white"
          transform="translate(0.457031)"
        />
      </clipPath>
    </defs>,
  ],
});

export default MobilePhoneIcon;
