import { Flex, Link, Image, FlexboxProps } from "@chakra-ui/react";
import { get } from "lodash/fp";
import { SignicatLink, SignicatLoginUrl } from "apollo/generatedTypes";

import { default as nordea } from "pages/ssoIcons/nordea.svg";
import { default as danskebank } from "pages/ssoIcons/danske-bank.svg";
import { default as op } from "pages/ssoIcons/osuuspankki.svg";
import { default as aktia } from "pages/ssoIcons/aktia.svg";
import { default as alandsbanken } from "pages/ssoIcons/aland-small.png";
import { default as saastopankki } from "pages/ssoIcons/saastopankki.svg";
import { default as omasp } from "pages/ssoIcons/oma-saastopankki.svg";
import { default as pop } from "pages/ssoIcons/pop-pankki.svg";
import { default as handelsbanken } from "pages/ssoIcons/handelsbanken.svg";
import { default as spankki } from "pages/ssoIcons/s-pankki.svg";
import { default as telia } from "pages/ssoIcons/mobiilivarmenne.svg";
import { default as bankId } from "pages/ssoIcons/bankId.png";
import { useIntl } from "react-intl";

export const genericSignicatLogin = "GENERIC_SIGNICAT_LOGIN";

const icons = {
  nordea,
  danskebank,
  op,
  aktia,
  alandsbanken,
  saastopankki,
  omasp,
  pop,
  handelsbanken,
  spankki,
  telia,
  bankId,
};

const SignicantProviderBox = ({
  provider,
  genericLogin,
}: {
  provider: SignicatLink | SignicatLoginUrl;
  genericLogin: boolean;
}) => {
  const { locale } = useIntl();

  const iconString = provider.method
    ?.split("-")
    .filter((str) => str !== "fi")[0];
  const icon = iconString && get([iconString], icons);
  if (!icon) {
    console.warn("No icon found for:", provider?.method);
    return null;
  }

  const href = window.location.href.replace("strongAuthFailed=true", "");

  const params = new URLSearchParams(provider?.url?.split("?")[1] as string);
  params.set("state", params.get("state") + `;${href}`);
  params.set("ui_locales", locale);
  if (genericLogin) {
    params.set("state", params.get("state") + `;${genericSignicatLogin}`);
  }
  const urlWithRedirectState = new URL(
    `${provider?.url?.split("?")[0]}?${decodeURIComponent(params.toString())}`
  );

  return (
    <Link
      width="90px"
      height="90px"
      key={provider?.method}
      background="gray.100"
      alignItems="center"
      justifyContent="center"
      display="flex"
      href={urlWithRedirectState.href as string}
      borderRadius="4"
      _hover={{
        background: "gray.300",
      }}
      _active={{
        background: "blue.50",
      }}
    >
      <Image src={icon.src} alt="" width="60px" objectFit="contain" />
    </Link>
  );
};

const SignicatDropdown = ({
  signicatLoginUrls,
  boxShadow,
  genericLogin,
  ...rest
}: {
  signicatLoginUrls: SignicatLink[] | SignicatLoginUrl[];
  boxShadow?: string;
  genericLogin?: boolean;
  rest?: FlexboxProps;
}) => {
  return (
    <Flex
      flex={1}
      flexDirection="row"
      flexWrap="wrap"
      gap="2"
      boxShadow={boxShadow ?? "xl"}
      p="4"
      borderBottomLeftRadius="12"
      borderBottomRightRadius="12"
      overflow="hidden"
      {...rest}
    >
      {signicatLoginUrls?.map((signi) => (
        <SignicantProviderBox
          key={signi.method}
          provider={signi}
          genericLogin={!!genericLogin}
        />
      ))}
    </Flex>
  );
};

export default SignicatDropdown;
